<template>
  <div class="container">
    <div class="text">
      <p>关注微信公众号"心动短剧社"</p>
      <p>观看精彩后续+参与抽取IPhone14Pro</p>
    </div>
    <div class="money">￥<span>{{money==49.9?888:188}}</span></div>
    <div class="pay-btn" @click="onChouJiang"><img :src="oss+'success-butn.png'"/></div>
    <div class="pay-btn2" @click="btn(query.wechat_url)"><img :src="oss+'success-button-2.png'"/></div>
  </div>

</template>
<script>
  export default {
    components: {},
    data () {
      return {
        query:{},
        money:'',
      }
    },
    created(){
      this.query = this.$route.query
      const {money} = this.$route.query

      this.money = money
    },
    computed:{
    },
    mounted(){
    },
    methods:{
      btn(url){
        window.open(unescape(url))
      },
      onChouJiang(){
        this.$router.push({path:'/lottery',query:{order_sn:this.query.order_sn}})
      }
    }
  }

</script>
<style lang="less" scoped>
  .container{background:#ff0303 url('@{static-dir}duangju_success.png?v03') no-repeat 0px -60px;background-size:100% auto;padding:84px 30px 0px;min-height:100vh;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;
    .text{color:#fff;text-align:center;font-size:18px;line-height:28px;}
    .money{color:#e03834;text-align:center;font-size:28px;font-weight:700;margin-top:64px;height:258px;
      span{font-size:62px;}
    }
    .pay-btn{width:88%;margin:0px auto 20px;animation:2s ease-in-out 0s infinite normal none running scaleDraw;}
    .pay-btn2{}
    img{width:100%;}
  }
  @keyframes scaleDraw{
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.2);
    }
  }
</style>
